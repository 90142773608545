<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button class="mr-3" color="primary" type="filled" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)"></vs-button>
            <vs-button class="mr-3" color="primary" type="border" icon-pack="feather" icon="icon-refresh-cw" @click="getData(true)"></vs-button>
          </div>
        </div>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="w-full" placeholder="Cari SPK" v-model="table.search" @keyup="onSearch"/>
          </div>
        </div>
      </div>
    </vx-card>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

    <!--empty-->
    <div class="w-full mt-10" v-if="isEmpty && !table.loading">
      <div class="flex items-center justify-center">
        <img class="w-64 h-auto m-4 opacity-75" src="@/assets/svg/under_construction.svg" alt="Empty"/>
      </div>
      <p class="text-center opacity-75">Tidak ada data.</p>
    </div>

    <!--data-->
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 lg:w-1/2 xl:w-1/3 mb-3 cursor-pointer" v-for="item in table.rows" :key="item.id">
        <vx-card @click="onClickCardSpk(item.id)">
          <div class="flex justify-between mb-3">
            <h4>{{ '#' + item.no_spk }}</h4>
          </div>
          <h3 class="mb-1 font-bold text-primary">{{ item.nama_proyek }}</h3>
          <span>SPK {{ item.jenis_spk }} - {{ item.jenis_kontrak }}</span>
          <div class="vx-row mt-3">
            <div class="vx-col">
              <vs-chip>
                <vs-avatar icon-pack="feather" icon="icon-home"/>
                <span><strong class="text-primary">Rekanan :</strong> {{ item.nama_rekanan }} </span>
              </vs-chip>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col">
              <vs-chip>
                <vs-avatar icon-pack="feather" icon="icon-calendar"/>
                <span><strong class="text-primary">Tgl. Awal :</strong> {{ item.tgl_awal }}  </span>
              </vs-chip>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col">
              <vs-chip>
                <vs-avatar icon-pack="feather" icon="icon-calendar"/>
                <span><strong class="text-primary">Tgl. Akhir :</strong> {{ item.tgl_akhir }}  </span>
              </vs-chip>
            </div>
          </div>
          <div class="vx-row"><br></div>
          <div class="vx-row">
            <div class="vx-col w-full w-1/1 mb-3">
              <small>Pekerjaan <strong class="text-success">{{ item.pekerjaan_has_complete_percent | idr }}%</strong> Selesai : <strong class="text-success">{{ item.pekerjaan_has_complete }} / {{ item.pekerjaan_to_complete }}</strong> items </small>
              <vs-progress class="block mt-1" :percent="item.pekerjaan_has_complete_percent" color="success"></vs-progress>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full w-1/1 mb-3">
              <small>Pembayaran <strong class="text-warning">{{ item.payment_has_complete_percent | idr }}%</strong> Terbayar : <strong class="text-warning">Rp.
                {{ item.payment_has_complete | idr }}</strong></small>
              <vs-progress class="block mt-1" :percent="item.payment_has_complete_percent" color="warning"></vs-progress>
            </div>
          </div>
          <br>
        </vx-card>
      </div>
    </div>

    <!--load more-->
    <div class="w-full" v-if="table.rows.length < table.totalItems">
      <div class="flex items-center justify-center">
        <vs-button @click="loadMore" class="py-3 px-2 font-medium text-lg" :disabled="table.loading" type="flat" color="dark" icon-pack="feather" icon="icon-chevrons-down">
          <span v-if="table.loading" class="animate-pulse">Loading ...</span>
          <span v-if="!table.loading">Load More</span>
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'
import constant from '@/constant/constant'
import _ from 'lodash'

export default {
  name: 'ProgressUnitListSpk',
  props: {},
  mounted () {
    this.onPageChange()
  },
  data () {
    return {
      filter: {},
      table: {
        loading: false,
        page: 1,
        limit: 18,
        totalItems: 0,
        sort: null,
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmpty () {
      return this.table.rows.length < 1
    }
  },
  methods: {
    getData (isReplaceData = false) {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      // external filter
      const externalFilterMandatory = {
        jenis_spk: constant.JENIS_SPK.UNIT,
        jenis_kontrak: constant.JENIS_KONTRAK_SPK.JASA,
        status_approval: constant.STATUS_APPROVAL.DITERIMA,
        id_properti_unit: this.$route.params.idPropertiUnit,
        withPaymentInformation: true,
        withPekerjaanInformation: true
      }
      params.externalFilter = JSON.stringify(externalFilterMandatory)

      ProgressUnitRepository.getSpk(params)
        .then(response => {
          if (isReplaceData) {
            this.table.rows = response.data.data.data
          } else {
            _.each(response.data.data.data, item => this.table.rows.push(item))
          }
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    onClickCardSpk (idSpk) {
      this.$router.push({
        name: 'proyek.progressUnit.asTeknik.listSpk.itemPekerjaanSpk',
        params: { idPropertiUnit: this.$route.params.idPropertiUnit, idSpk: idSpk }
      })
    },

    loadMore () {
      this.table.page++
      this.onPageChange()
    },

    onPageChange () {
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.table.page = 1 // reset page
      this.getData(true)
    }, 500)
  }
}
</script>
